:root {
  --bg: #282c34;
  --primary: #3ecfa5;
  --secondary: #fb686b;
  --link: #61dafb;
  --text: #f8f6f3;
  --ani-time-1: 0.75s;
  --ani-time-2: 0.5s;
}

html, body {
  background-color: var(--bg);
}

.App {
  background-color: var(--bg);
  min-height: 100vh;
}

.page-content {
  font-size: calc(10px + 2vmin);
  background-color: var(--bg);
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  color: var(--text);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.section {
  border-top: 1px solid var(--secondary);
  animation: border-fade-in;
  animation-duration: var(--ani-time-2);
  animation-fill-mode: backwards;
}

header {
  padding: 1rem 1rem 2rem;
}

.header-grid {
  display: grid;
  grid-template-columns: auto auto;
}

.name-header, .title-subheader {
  margin: 0;
}

.header-column .image-wrapper {
  width: 6rem;
  height: 6rem;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  float: right;
  border: 2px solid var(--primary);
}

.header-column img {
  display: inline;
  width: 100%;
  height: auto;
}

img, .icon {
  animation: image-fade-in var(--ani-time-1);
}

.tool, .lang {
  font-size: 3.5rem;
  padding: 0 0.5rem;
}

.image-cropper {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.sm-none {
  display: none;
}

.info {
  padding: 2rem 0px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 50%));
}

.list {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.list li {
  padding-bottom: 0.5rem;
}

.list .icon {
  text-align: center;
  width: 2rem;
  padding-right: 0.5rem;
}

.link {
  text-decoration: none;
  color: var(--link);
  display: inline-block;
}

.link:hover {
  animation: pulse 1s infinite;
  animation-timing-function: linear;
}

dl {
  margin-block-start: 1.5em;
  margin-block-end: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin-top: 0.5rem;
  font-size: 0.85em;
}

@media screen and (min-width: 768px) {
  .sm-none {
    display: block;
  }

  .heading-background {
    background-color: var(--primary);
    height: 15rem;
    box-shadow: 0 2px 1px 0 var(--secondary);
    animation: background-grow, shadow-fade-in;
    animation-duration: var(--ani-time-1), var(--ani-time-2);
    animation-delay: 0s, var(--ani-time-1);
    animation-fill-mode: none, backwards;
  }

  .heading-border {
    height: 10rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 0 1px 2px var(--secondary);
    animation: shadow-fade-in;
    animation-duration: var(--ani-time-2);
    animation-delay: var(--ani-time-1);
    animation-fill-mode: backwards;
  }

  .heading-border, .page-content {
    width: 80%;
    max-width: 900px;
    margin: -10rem auto 0;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .page-content {
    min-height: 8rem;
    animation: slide-up;
    animation-duration: var(--ani-time-1);
  }

  .section {
    animation-delay: var(--ani-time-1);
  }

  .header-column .image-wrapper {
    width: 10rem;
    height: 10rem;
  }

  .list .icon {
    width: 3rem;
    padding-right: 1rem;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    color: var(--link);
  }
  50% {
    transform: scale(1.1);
    color: var(--primary);
  }
  100% {
    transform: scale(1);
    color: var(--link);
  }
}

@keyframes slide-up {
  from {
    margin-top: 5rem;
  }

  to {
    margin-top: -10rem;
  }
}

@keyframes background-grow {
  from {
    height: 0px;
  }

  to {
    height: 15rem;
  }
}

@keyframes image-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes shadow-fade-in {
  from {
    box-shadow: none;
  }

  to {
    box-shadow: 0 0 1px 2px var(--secondary);
  }
}

@keyframes border-fade-in {
  from {
    border-top: 1px solid var(--bg);
  }

  to {
    border-top: 1px solid var(--secondary);
  }
}